<template>
  <r-laptop v-if="$r.breakpoint.mdAndUp"></r-laptop>
  <r-mobile v-else></r-mobile>
  <r-modal
    full-width
    class="px-2 px-md-10 px-lg-12"
    :closebtn="false"
    :model-value="$r.store.showCart || false"
  >
    <div class="color-white"><order-card show-action></order-card></div>
  </r-modal>
  <r-btn v-show="show" v-scroll="set" class="color-one btn-up" fab @click="top">
    <r-icon v-html="$r.icons.chevron_up"></r-icon>
  </r-btn>
</template>

<script>
import {defineAsyncComponent} from "@vue/runtime-core";
import OrderCard from "../../components/orderCard";

export default {
  name: "index",
  components: {
    OrderCard,
    RLaptop: defineAsyncComponent(() => import("./laptop")),
    RMobile: defineAsyncComponent(() => import("./mobile")),
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$axios.get("home/settings/multi/law_order").then(({ data }) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i]["name"] === "law_order") {
          this.$r.store["law_order"] = data[i]["value"];
        }
      }
    });
  },
  methods: {
    top() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    set() {
      if (window.scrollY > 20) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
.template-home {
  .router-link-exact-active {
    color: var(--color-one-light) !important;
  }
}
</style>
